import React from 'react';
import styled from 'styled-components';

import { BtnMore } from '../Hero/Hero';

import howitworks from '../../data/howitworks';
import howitWorksImage1 from '../../assets/images/howitworks-projektowanie.png';
import howitWorksImage2 from '../../assets/images/howitworks-produkcja.png';
import howitWorksImage3 from '../../assets/images/howitworks-pakowanie.png';
import howitWorksImage4 from '../../assets/images/howitworks-wysylka.png';

const howitWorksImages = [
  howitWorksImage1,
  howitWorksImage2,
  howitWorksImage3,
  howitWorksImage4,
];

const StyledHeader = styled.h2`
  color: ${props => props.theme.primary};
  font-size: 62px;
  text-align: center;
  font-weight: 100;
  margin-bottom: 90px;

  strong {
    font-weight: 600;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.desktopSm}) {
    margin-bottom: 120px;
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    margin-bottom: 80px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 35px;
    display: block;
    margin: 0 auto;
    line-height: 41px;
  }
`;

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 160px 0;
  background: #f8f8f8;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 100px 0;
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 70px 0;
  }
`;

const StyledContainer = styled.div`
  padding: 0 30px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: ${props => props.theme.breakpoints.desktopSm}) {
    flex-wrap: wrap;
  }
`;

const StyledHeading = styled.h4`
  font-size: 15px;
  font-weight: 700;
  color: ${props => props.theme.primary};
  margin-bottom: 20px;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 16px;
  }
`;

const StyledParagraph = styled.p`
  font-size: 15px;
  color: ${props => props.theme.primary};
  font-weight: 400;
  line-height: 27px;
  font-family: 'Manrope';
`;

const ImageWrapper = styled.div`
  height: 310px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-child(2n) {
    height: 270px;
  }
`;

const ItemBlock = styled.div`
  max-width: 305px;
  margin: 0 15px;
  text-align: center;
  
  &:not(:last-child) {
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      margin-bottom: 40px;
    }
  }
  
  &:nth-child(even) {
    margin-top: -50px;

    @media screen and (max-width: ${props =>
  props.theme.breakpoints.desktopSm}) {
      margin-top: 0;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      margin-top: 10px;
    }

    ${ImageWrapper} {
      height: 250px;

      @media screen and (max-width: ${props =>
  props.theme.breakpoints.desktopSm}) {
        height: 300px;
      }

      @media screen and (max-width: ${props =>
  props.theme.breakpoints.tablet}) {
        height: auto;
      }
    }
  }

  &:nth-child(odd) {
    @media screen and (max-width: ${props =>
  props.theme.breakpoints.desktopSm}) {
      margin-top: -50px;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      margin-top: 10px;
    }

    ${ImageWrapper} {
      @media screen and (max-width: ${props =>
  props.theme.breakpoints.desktopSm}) {
        height: 250px;
      }

      @media screen and (max-width: ${props =>
  props.theme.breakpoints.tablet}) {
        height: auto;
      }
    }
  }
`;

const StyledBtnMore = styled(BtnMore)`
  display: inline-block;
  margin: 60px auto 0;
`;


const HowItWorks = (props) => {

  return (
    <StyledSection>
      {props.heading && <StyledHeader>Jak to <strong>działa?</strong></StyledHeader>}
      <StyledContainer>
        {howitworks.map((item, index) => (
          <ItemBlock data-aos={props.shouldAnimate ? `${index % 2 === 0 ? 'fade-up' : 'fade-down'}` : ''} key={index}>
            <ImageWrapper>
              <img loading="lazy" src={howitWorksImages[index]} alt={item.name}/>
            </ImageWrapper>
            <StyledHeading>{item.name}</StyledHeading>
            <StyledParagraph>{item.text}</StyledParagraph>
          </ItemBlock>
        ))}
      </StyledContainer>
      {props.children}
    </StyledSection>
  );
};

export default HowItWorks;
